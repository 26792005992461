












import { Component, Vue } from "vue-property-decorator";
import LoginLayout from "../components/commons/layouts/LoginLayout.vue";

@Component({
  components: {
    LoginLayout,
  },
})
export default class ResetPasswordPage extends Vue {}
